<h2>Vérifier si un site est fiable</h2>
    <h3 class="lead">Comment vérifier si on peut faire confiance à un site internet ?</h3>
    <br>
        <h4>1. Vérifier si la communication est sécurisée</h4>
            <p>
                La première étape consiste à vérifier si le site utilise bien le protocole de 
                communication HTTPs. Cette information s'affiche facilement en haut de votre 
                navigateur comme le montre l'image ci-dessous.
            </p>
            <img src="assets/img/help/https.jpg">
            <p>
                Cette vérification permet de confirmer que la communication entre votre poste de travail
                et le serveur hébergeant le site internet est chiffrée et ne peut pas être consultée par d'autres
                appareils étant sur le même réseau que vous.
            </p>
            <p>
                <strong>Attention, même si la communication est chiffrée, cela ne veut pas dire pour autant que 
                c'est un site de confiance.</strong> Cela permet uniquement de protéger les informations transitant
                entre votre poste et le site internet. Ceci est très fortement recommandé, surtout lors de la consultation
                de site internet depuis un Wi-Fi ou un réseau public (ex: McDo, Gare, etc..)
            </p>
            <br>
        <h4>2. Vérifier un lien</h4>
            <p>
                Lorsqu'on clique sur un lien dans un email ou autres, on peut arriver sur n'importe quel site. 
                Je vous donne par exemple le lien suivant <a href="/help/fakeScam" target="_blank">https://youtube.com</a>.
                Visuellement on a l'impression que si l'on clique dessus, on va se rendre sur le site YouTube, mais ce n'est
                qu'une impression, le lien redirige en réalité vers le site suivant : <i>https://reiter.tf/help/fakeScam</i>.
            </p>
            <p>
                On peut vérifier la destination réelle d'un lien en plaçant le curseur sur le lien. L'image ci-dessous illustre bien
                ceci.
            </p>
            <img src="assets/img/help/link.jpg">
            <br>
            <br>
        <h4>3. Vérifier une URL</h4>
            <p>
                Premièrement, qu'est qu'une URL ? Une URL (Uniform Resource Locator) est un format de nommage universel 
                pour désigner une ressource sur Internet. Cela correspond aux noms <i>youtube.com/profil</i>, <i>google.fr/search?q=url</i>, etc..
            </p>
            <p>
                Une URL peut se découper en plusieurs morceaux comme le montre le schéma ci-dessous.
            </p>
            <img src="assets/img/help/url.jpg">
            <p>
                Faisons abstraction de tout ce qui est après le nom de domaine (après le premier /). Il ne nous reste alors que le nom de domaine du site internet.
                Ces noms de domaines sont constitués de plusieurs domaines séparés par des points. Ils s'organisent de manière hiérarchique de droite à gauche.<br>
                Dans l'url <i>gaming.youtube.com</i>, le domaine de premier niveau est <i>com</i>, puis celui de deuxième niveau est <i>youtube</i>, et enfin celui de troisième
                niveau est <i>gaming</i>.
            </p>
            <p>
                Lors de la consultation d'un site internet, il faut uniquement vérifier les domaines de premier et de deuxième niveau. Dans notre exemple précédent, on 
                ne gardera alors que <i>youtube.com</i>. Maintenant il faut se poser la question suivante, est-ce que je fais confiance à <i>youtube.com</i>. Si la réponse
                est oui, alors on peut faire confiance à la page affichée dans le navigateur.
            </p>
            <p>
                <strong>Attention tout de même aux domaines malicieux</strong>. Prenons comme exemple le site des impôts français. L'url officielle est la suivante :
                <i>www.impots.gouv.fr/portail/</i>. En ne gardant que les 2 premiers niveaux on trouve <i>gouv.fr</i>, on connait ce site, on peut donc faire confiance à ce site.<br>
                Ce n'est pas le cas de l'url <i>https://www-impots-gouv.fr/portail/</i>. Ici le domaine constitué des deux premiers niveaux est <i>www-impots-gouv.fr</i>, 
                ce site n'est pas digne de confiance. 
            </p>
            <p>
                En résumé, lors de la consultation d'un site internet, il faut regarder uniquement les premières parties du nom de domaine (en partant de la droite) et faire
                attention aux séparateurs (des points et non un autre caractère).
            </p>
            <br>
        <h4>3. Obtenir plus d'informations</h4>
            <p>
                De manière générale, il peut être utile d'aller consulter les CGU/CGV, les pages de contacts, etc. sur les sites où l'on éprouve un doute.                
                De plus il sera tout plus sécurisé d'aller sur le site de nous-mêmes que de cliquer sur un lien.
            </p>
        <br>
        <br>
        <br>
        <br>
