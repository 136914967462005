import { Component, OnInit, Input } from '@angular/core';
import Projects from '../../../assets/projects.json';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
  @Input() projectId: string;
  project: any;
  location: string;

  constructor() {
    this.location = window.location.href;
  }

  ngOnInit(): void {
    this.project = Projects[this.projectId];
  }
}
