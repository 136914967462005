<nav class="navbar navbar-expand-lg navbar-dark">
  <a class="navbar-brand" [routerLink]="['/']">
    <img src="assets/img/logo-min.png" width="30" height="30" class="d-inline-block align-top" alt="" loading="lazy">
    Arthur R.
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
    <ul class="navbar-nav justify-content-end">
      <li class="nav-item" [ngClass]="{'active': this.location.isCurrentPathEqualTo('/')}">
        <a id="nav-home" class="nav-link" [routerLink]="['/']">Home</a>
      </li>
      <li class=" nav-item" [ngClass]="{'active': this.location.isCurrentPathEqualTo('/about')}">
        <a id="nav-about" class="nav-link" [routerLink]="['/about']">About</a>
      </li>
      <li class="nav-item" [ngClass]="{'active': this.location.isCurrentPathEqualTo('/portfolio')}">
        <a id="nav-portfolio" class="nav-link" [routerLink]="['/portfolio']">Portfolio</a>
      </li>
    </ul>
  </div>
</nav>
