<div class="card" style="width: 18rem;">
  <img [src]="'assets/img/projects/'+project.img" class="card-img-top" alt="...">
  <div class="card-body">
    <h5 class="card-title">{{project.title}}</h5>
    <p class="card-text">{{project.description}}</p>


    <a target="_blank" *ngIf="project.link !== undefined" [href]="project.link" class="btn btn-sm btn-primary">More
      information</a>
  </div>
  <div class="card-footer">
    <small class="text-muted"><i class="far fa-calendar-alt"></i> {{project.dateStart}}</small>
    <small *ngIf="project.dateEnd !== ''" class="text-muted"> - {{project.dateEnd}}</small>

    <br>
  </div>
</div>
