import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Projects from '../../../assets/projects.json';

@Component({
  selector: 'app-page-portfolio',
  templateUrl: './page-portfolio.component.html',
  styleUrls: ['./page-portfolio.component.scss'],
})
export class PagePortfolioComponent implements OnInit {
  projects: any;
  displayedProjects: any;
  category = ['All', 'Development', 'Design', 'Photography', 'Organisation', 'Cybersecurity', 'Filmmaking'];
  currentCategorie: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.projects = Projects;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.currentCategorie = params.getAll('category');

      if (!this.category.includes(this.currentCategorie.toString())){
        this.router.navigate(['/portfolio/All'])
      }
    });
  }
}
