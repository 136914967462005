<h1>Portfolio</h1>


<a *ngFor="let cat of this.category" [routerLink]="['/portfolio', cat]" class="badge badge-pill"
  [ngClass]="(cat === this.currentCategorie.toString())?'badge-primary':'badge-dark'">{{cat}}</a>

<div class="row justify-content-md-center">
  <div class="card-deck">
    <ng-container *ngFor="let project of projects; index as i">
      <app-project
        *ngIf="project.category.includes(this.currentCategorie.toString()) || this.currentCategorie.toString() === 'All'"
        [projectId]="i">
      </app-project>
    </ng-container>
  </div>

</div>