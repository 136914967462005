import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageHomeComponent } from './pages/page-home/page-home.component';
import { PageAboutComponent } from './pages/page-about/page-about.component';
import { PagePortfolioComponent } from './pages/page-portfolio/page-portfolio.component';
import { PageHelpComponent } from './pages/page-help/page-help.component';
import { PageFakeScamComponent } from './pages/page-fake-scam/page-fake-scam.component';

const routes: Routes = [
  { path: '', component: PageHomeComponent },
  { path: 'about', component: PageAboutComponent },
  { path: 'portfolio', component: PagePortfolioComponent },
  { path: 'portfolio/:category', component: PagePortfolioComponent },
  { path: 'help', component: PageHelpComponent },
  { path: 'help/fakeScam', component: PageFakeScamComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
