<script src="./txt-rotate.js"></script>
<div>
  <div class="centerPseudo mx-auto" style="max-width: 40%;">
    <div class="row">
      <div class="col-12 col-md-4 col-sm-12 align-self-center">
        <img src="assets/img/profil-min.jpg" alt="..." class="img-thumbnail border-0 rounded-circle">
      </div>
      <div class="col align-self-center">
        <h1>Arthur REITER</h1>
        <h3>I'm a
          <span class="txt-rotate" data-period="1400"
            data-rotate='[ "DevOps engineer.", "developer.", "photographer.", "drummer.", "film maker." ]'></span>
        </h3>
        <div class="row">
          <div class="col-auto">
            <a class="icon-link" target="_blank" href="https://github.com/A3lfyr/">
              <span class="icon is-large">
                <i class="fab fa-github fa-2x"></i>
              </span>
            </a>
          </div>
          <div class="col-auto">
            <a class="icon-link" target="_blank" href="https://instagram.com/2behindthelens">
              <span class="icon is-large">
                <i class="fab fa-instagram fa-2x"></i>
              </span>
            </a>
          </div>
          <div class="col-auto">
            <a class="icon-link" target="_blank" href="https://www.youtube.com/@phoqus">
              <span class="icon is-large">
                <i class="fab fa-youtube fa-2x"></i>
              </span>
            </a>
          </div>
          <div class="col-auto">
            <a class="icon-link" target="_blank" href="https://www.linkedin.com/in/arthur-reiter/">
              <span class="icon is-large">
                <i class="fab fa-linkedin fa-2x"></i>
              </span>
            </a>
          </div>
          <div class="col-auto"><a class="icon-link" target="_blank" href="mailto:arthur.reiter231@gmail.com">
              <span class="icon is-large">
                <i class="fas fa-envelope fa-2x"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
