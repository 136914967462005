<h1>About me</h1>
<h2 class="lead">
  DevOps engineer - Developer - Film maker - Photographer - Musician
</h2><br>
<p>
  I like to put my knowledge into practice by carrying out multiple projects. Programming, Web Design,
  Computer Graphics, Photography, Music are my main passions. Drummer since the age of 9 and amateur
  photographer for a few years, I share my vision of the world on <a target="_blank"
  href="https://instagram.com/2behindthelens">my Instagram account</a> or in <a target="_blank"
  href="https://www.youtube.com/@phoqus">my YouTube account</a>. You can find all my work in the
  <a [routerLink]="['/portfolio']">portfolio</a> section.
</p><br>
<p>
  If you want more information about my career, feel free to take a look at my <a target="_blank"
    href="https://fr.linkedin.com/in/arthur-reiter">LinkedIn profile</a>. If you want to learn more about my projects, 
    you can check out my <a [routerLink]="['/portfolio']">portfolio</a>.
</p><br>
<p>
  Thank you and enjoy your visit :)
</p>
